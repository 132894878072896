/* Title and Description */
.title-class {
  font-weight: bold;
  color: var(--black);
  cursor: pointer;
  font-family: sans-serif;
  font-size: 1.2rem;
}

.description-class {
  color: var(--white);
  font-style: italic;
}

.selector-button {
  background-color: #333333 !important;
  border-color: transparent !important;
  cursor: pointer;
  transition-duration: 0.3s;
  color: white;
  height: 60px;
  border: none !important;
  margin: 10px !important;
  width: auto;
}

.selector-button:hover {
  background-color: var(--yellow) !important;
  border-color: var(--yellow) !important;
  color: black !important;
}

.div-class:hover .title-class {
  color: var(--white) !important;
}

/* Grid Container */
.field-container {
  display: grid !important;
  max-width: 1606px;
  padding: 30px;
  width: 100% !important;
  margin: auto;
  padding: 0;
  grid-template-columns: repeat(
    auto-fit,
    minmax(min(100%/1, max(400px, 100%/3)), 1fr)
  );
}

/* Responsive */
@media (min-width: 500px) {
  .div-class {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
}

.field-select-header {
  font-family: var(--font-family-roboto);
  color: var(--white);
  font-weight: bold;
}

.btn-margin {
  margin: 1% !important;
}

.extra-large-button {
  width: 80%;
  height: 65px;
  font-size: 20px;
}
