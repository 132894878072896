/* global.css */

:root {
  --black: #000;
  --white: #fff;
  --yellow: #fdd835;
  --dark-grey: #333;
  --grey: #888;
  --font-family-roboto: "Roboto", sans-serif;
}

/* General page layout styles */
html,
body,
#root,
.container-fluid,
.row {
  height: 100%;
  margin: 0;
  padding: 0;
}

/* Global Styles */
body {
  background-color: var(--black);
  color: var(--white);
  margin: 0;
  padding-left: 32px;
  padding-right: 32px;
  height: 100%;
  font: var(--font-family-roboto);
  font-size: 16px;
  padding-top: 0px; /* Adjust this value based on the height of your navbar */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--white) !important;
}

/* Major second typescale */
h1 {
  font-size: 2.986rem;
  font-weight: 400;
  line-height: 50px;
  margin: 20px 0;
}

h2 {
  font-size: 1.802rem;
  font-weight: bold;
  margin-bottom: 0.5em;
}

h3 {
  font-size: 1.602rem;
}

h4 {
  font-size: 1.424rem;
}

h5 {
  font-size: 1.266rem;
}

h6 {
  font-size: 1.125rem;
}

p {
  font-size: 1rem;
}

h3,
h4,
h5,
h6 {
  font-weight: bold;
}

p {
  font-size: 1rem;
}

button {
  font-family: var(--font-family-roboto);
  font-size: 16px;
  font-weight: bold;
  color: var(--white);
  border: none;
  border-radius: 10px;
  margin: 2% !important;
  cursor: pointer;
  width: 55%;
}

button:hover {
  background-color: var(--yellow);
  color: var(--black);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.main-content {
  max-width: 1586px;
  margin: auto;
  min-height: 80%;
}

[data-bs-theme="dark"] {
  background-color: (var(--dark-grey)); /* Replace with your desired color */
  color: var(--white);
}

/* Media Queries */
@media (max-width: 959px) {
  body {
    padding: 0;
  }
}
