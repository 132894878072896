.center-row {
  justify-content: center;
}

.small-button {
  width: 30%;
  height: 35px;
  font-size: 14px;
}

.large-button {
  width: 60%;
  height: 55px;
  font-size: 18px;
}

.zone-container {
  min-width: 350px;
  margin: 10px;
  max-width: 600px;
  display: flex;
  background: var(--dark-grey);
  border-radius: 10px;
  display: inline-grid;
  height: fit-content;
  border: none !important;
}

.my-border-style {
  border-color: var(--yellow) !important;
  border-width: 0.1px;
  border: ridge;
}

.my-content {
  width: 90%;
}

.color-button-style {
  text-wrap: auto;
  background-color: #333;
  border: 0;
  border-radius: 10px;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 45px;
  margin: 2% !important;
  width: 25%;
  border: none !important;
}

.scene-active-message {
  color: var(--white);
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  font-weight: bold;
}

.title,
.zone-title {
  color: white;
  font-weight: bold;
  margin: 0.5rem;
}

.title {
  font-size: 1.125rem;
  margin-bottom: 10px;
}

.zone-title {
  font-size: 25px;
  margin-bottom: 0.5rem;
  font-weight: bolder;
}

.no-blank {
  border-radius: 10px;
  font-family: var(--font-family-roboto);
  color: var(--white);
  font-size: 16px;
  margin: 2% !important;
  border: none !important;
  min-width: 40%;
  width: 80% !important;
  background-color: var(--grey) !important;
}

.tylers-btn {
  margin: 0px !important;
  padding: 0px !important;
}

.collapse-button {
  margin: auto;
  padding: auto;
  width: fit-content;
}

.field-card-header {
  padding: auto;
  margin: auto 0px;
}
