footer {
  max-width: 1420px;
  margin: auto;
  padding-top: 20px;
  margin-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  position: fixed;
}

@media (max-width: 991px) {
  .footer-col {
    flex: 0 0 100%; /* Make the columns take full width */
    max-width: 100%;
    margin-bottom: 1rem; /* Add space between stacked rows */
    text-align: center; /* Optional: center-align the text */
  }
}
