.offline-page {
  height: 100vh;
  background-color: transparent;
}

.offline-alert {
  max-width: 600px;
  margin: auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}

.offline-alert h2 {
  font-size: 2rem;
}

.offline-alert p {
  font-size: 1.2rem;
}

.offline-message {
  margin: 20px;
  display: grid;
  justify-content: center;
  align-self: center;
}
