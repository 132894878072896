.user-list-item {
  background-color: transparent;
  color: var(--white);
  display: flex;
  justify-content: space-between;
  align-items: center;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0px 10px;
}

.card-title {
  color: var(--white);
  background-color: transparent;
  padding-top: 10px;
}

.card-body {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form {
  width: 100%;
}

.my-button-style-settings {
  display: block;
  margin: 0px !important;
  font-weight: bold;
  width: 100%;
  background-color: #333333;
  font-family: Roboto, sans-serif;
  color: var(--white);
  height: 45px;
  border: 0;
  font-size: 16px;
}

.my-button-style-settings:hover {
  background-color: var(--yellow);
  color: var(--black);
}

@media (max-width: 768px) {
  .user-list-item {
    width: 100%;
  }
}

.list-group-item {
  border: none !important;
  border-bottom: 1px #444 solid !important;
  border-radius: 0px !important;
}
