.card-custom-width {
  width: 45% !important;
  margin: auto;
  background: var(--dark-grey);
}

.user-email {
  flex-grow: 1;

  text-wrap: nowrap;
}

.remove-button {
  color: var(--red);
  cursor: pointer;
  margin-left: auto;
  border: 1px var(--red) solid !important;
  width: auto;
}

/* Sidebar styles */
#sidebar-wrapper {
  background-color: transparent;
  padding: 0;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
}

/* Main content styles */
#page-content-wrapper {
  margin-left: 16.666667%; /* Adjust this value based on sidebar width */
  padding: 20px;
  padding-top: 70px; /* Adjust this value based on the height of your navbar */
}

.link-custom {
  font-size: large !important;
  margin: 30px 30px 30px 10px !important;
}

@media (max-width: 991px) {
  .link-custom {
    font-size: large !important;
    margin: 30px 30px 30px 10px !important;
    border: 0px !important;
    margin: 0px !important;
  }

  .card-custom-width {
    margin: 10px 10px 0px 10px;
    border: none;
    padding: 10px !important;
    width: 95% !important;
  }
}

.modal-content {
  background-color: var(--dark-grey);
}

.cog-button {
  width: 50px;
  border: transparent;
}

.save-btn {
  border: green 1px solid !important;
}

.delete-btn {
  border: red 1px solid !important;
}

.bs-btn-close-color {
  color: white !important;
}

.bs-btn-close-color {
  color: white !important;
}
