/* button.css */

.button-style {
  font-family: var(--font-family-roboto);
  font-size: 16px;
  background-color: var(--grey) !important;
  color: var(--white);
  border-radius: 10px;
  border: none;
  width: 45% !important;
  min-width: 40%;
}

.selected-button {
  background-color: var(--yellow) !important;
  color: var(--black) !important;
  border: 0;
  outline-offset: 2px;
  outline-color: var(--white);
}

.button-style:hover {
  color: var(--white);
}
