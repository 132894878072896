/* customTable.css */
.table-custom {
  width: 100%;
  background-color: #282828;
  color: #fff;
  border: none;
}

.table-custom th,
.table-custom td {
  padding: 10px;
  border: none;
  background-color: #282828;
  color: white;
}

.table-custom th {
  text-align: left;
  font-weight: normal;
  background-color: #282828;
  color: white;
}

.table-custom td {
  border-top: 1px solid #444;
}

.table-custom .btn-custom {
  background: transparent;
  border: 1px solid var(--red);
  color: var(--red);
  margin-left: 10px;
  text-wrap: nowrap;
  width: auto;
}

.table-custom .btn-custom:hover {
  background-color: #444;
  border-color: #fff;
  color: #fff;
}

.scheduler-card {
  margin-top: 20px;
  padding: 10px;
  margin: 10px;
  background-color: var(--dark-grey) !important;
  color: white;
  max-width: 100%;
  width: auto !important;
  border: none;
}
.input-field {
  width: 100%;
  max-width: 190px;
  color: whitesmoke !important;
  background-color: #282828 !important;
}

.name-field {
  width: 300px !important;
}

.dropdown-toggle {
  margin: 0px !important;
  background: transparent;
}

/* styles.css or your global stylesheet */
.input-field::-webkit-calendar-picker-indicator {
  filter: invert(1); /* Inverts the color to white */
}

@media (max-width: 991px) {
  .scheduler-card {
    margin-top: 20px;
    padding: 10px !important;
    background-color: var(--dark-grey);
    color: white;
    width: 100%;
  }
  .input-field {
    width: 100%;
    color: whitesmoke !important;
    background-color: #282828;
  }
}

.schedule-btn {
  border-radius: 10px;
  font-family: var(--font-family-roboto);
  color: var(--white);
  font-size: 16px;
  border: none !important;
  min-width: 40%;
  width: 100%;
  background-color: var(--grey) !important;
  border: 1px solid #b0b0b0;
  color: white;
  margin-left: 0px !important;
}

.schedule-btn:hover {
  background: var(--yellow) !important;
  color: black;
}

.form-check-input {
  margin-right: 10px;
}

.form-check-label {
  margin-top: 10px;
}

.react-datepicker__header {
  background: #282828 !important;
}

.react-datepicker__day-name {
  color: white;
}

td {
  align-content: center;
}

.table-row-underline {
  border-bottom: 1px solid #444; /* White underline */
}

.form-select {
  background-color: #282828;
  color: white;
  width: 100%;
}
