/* Navbar Brand Logo */
.navbar-brand img {
  height: 24px; /* Adjust logo size */
}

.custom-navbar {
  margin: 0 auto; /* Center the navbar */
  padding: 0;
  position: relative; /* Ensure it's positioned relative to the document flow */
  z-index: 1040; /* A higher value than other content (Bootstrap uses 1040 for modals) */
}

.custom-navbar-nav {
  width: 100%;
}

.navbar {
  --bs-navbar-padding-y: 0rem;
  max-width: var(--max-width);
}

.account-link {
  padding: 2rem;
  border-bottom: 1px solid; /* Adjust the thickness and color as needed */
  border-color: currentColor; /* This will use the text color for the border */
}

/* Navigation Links */
.nav-link {
  font-size: 16px;
  position: relative;
  color: var(--white);
}

.nav-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background: var(--yellow);
  transition: width 0.1s;
  position: absolute;
  bottom: -3px;
  left: 0;
  text-decoration: underline;
}

.nav-link:hover::after,
.nav-link.active::after {
  width: 100%;
}

/* Sign Out Link */
.signout-link {
  text-decoration: none;
  color: var(--white);
  cursor: pointer;
  padding-right: 10px;
  text-align: end;
}

/* Header Lines (Additional Decoration) */
.header-lines {
  clip-path: polygon(
    100% 0,
    100% 0,
    100% 100%,
    65% 100%,
    100% 0,
    70% 0,
    35% 100%,
    25% 100%,
    60% 0,
    50% 0,
    15% 100%,
    100% 100%
  );
  display: inline-block;
  background: var(--yellow);
  width: 110px;
  height: 80px;
  margin-right: 10px;
  margin-top: -1px;
}

.user-icon {
  color: #ffffff; /* Adjust icon color */
  cursor: pointer;
}

.dropdown-toggle::after {
  display: none !important; /* Remove the default caret */
}

/* Basic dropdown styles with overflow protection */
.dropdown-menu {
  position: absolute;
  will-change: transform;
  right: 0; /* Align to the right edge of the container */
  left: auto; /* Override default Bootstrap behavior if necessary */
  transform-origin: right top; /* Pivot the dropdown animation */
}

.container {
  overflow: hidden;
  position: relative;
}

.dropdown-menu-end {
  right: 0;
  left: auto;
}

/* This is styling for mobile */
@media (max-width: 991px) {
  .navbar-brand img {
    size: 100% !important;
    margin: auto !important;
    margin-left: 10px !important;
  }

  .navbar-toggler {
    padding-top: 0 !important;
    width: auto;
    margin: auto;
  }

  .nav-link {
    font-size: 16px;
    padding: 2rem;
    padding-left: 20px;
    width: 100%;
    text-align: start;
    border-bottom: 1px solid; /* Adjust the thickness and color as needed */
    border-color: currentColor; /* This will use the text color for the border */
  }

  .navbar-collapse {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .signout-link {
    width: 100%;
    text-align: start;
  }

  .header-lines {
    display: none;
  }

  .navbar-collapse {
    background: var(--dark-grey);
    height: 900px;
    width: 991px;
  }
  .user-icon {
    display: none;
  }
}
