/**/

:root {
  --black: #000;
  --white: #fff;
  --yellow: #ffcf2e;
  --dark-grey: #282828;
  --grey: #333;
  --medium-gray: #616161;
  --red: #dc3545;
  --font-family: "Roboto", sans-serif;
  --max-width: 1586px;
}
